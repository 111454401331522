import { URL_REGEX } from '../../constants/regex.constant';

export default (image, options = {}) => {
  if (!image) return '';

  let imgUrl = '';
  if (URL_REGEX.test(image)) imgUrl = image;
  else {
    const formatedOptions = _cloudinaryURLFragment(options);
    imgUrl = `https://res.cloudinary.com/ideation/image/upload/${formatedOptions}${image}`
  }

  return imgUrl;
};

const _cloudinaryURLFragment = (options) => {
  const composition = [];

  // List of transformations accepted, if you need more, add from here:
  // http://cloudinary.com/documentation/image_transformations#transformations_reference

  if (options.width) {
    composition.push(`w_${options.width}`);
  }
  if (options.height) {
    composition.push(`h_${options.height}`);
  }
  if (options.crop) {
    composition.push(`c_${options.crop}`);
  }
  if (options.gravity) {
    composition.push(`g_${options.gravity}`);
  }
  if (options.radius) {
    composition.push(`r_${options.radius}`);
  }
  // Options for optimisation
  if (options.quality) {
    composition.push(`q_${options.quality}`);
  } else {
    composition.push('q_100'); // defaut quality to 100
  }
  if (options.format) {
    composition.push(`f_${options.format}`);
  } else {
    composition.push('f_auto'); // automatic image format
  }
  if (options.dpr) {
    composition.push(`dpr_${options.dpr}`);
  } else {
    composition.push('dpr_auto'); // automatic resolution
  }

  if (composition.length) {
    return composition.join(',') + '/';
  }

  return '';
}
